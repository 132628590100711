import { Box, HStack, Image } from "@chakra-ui/react";
import { LINKS } from "lib/utils/constants";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import type { ComponentProps } from "react";
import React from "react";
import { MenuLanguageGroupItem } from "./AdminSidebarLayout/Menu";
import { useLocales } from "src/contexts/LocalesContext";

export default function PublicCleanLayout({ children, withBackgroundColor }: ComponentProps<any>) {
  const { locales } = useLocales();
  const { route, query } = useRouter();
  const t = useTranslations("components.layout.adminSidebarLayout");
  return (
    <Box
      style={withBackgroundColor ? {
        // eslint-disable-next-line i18next/no-literal-string
        background: "linear-gradient(180deg, rgba(255,255,255,0) 70%, var(--chakra-colors-brandBlue-50) 100%)",
      } : {}}
    >
      <HStack
        top={0}
        left={0}
        w={"100%"}
        padding={10}
        position={"absolute"}
        justifyContent="space-between"
      >
        <Image
          // eslint-disable-next-line i18next/no-literal-string
          alt="myHeart"
          src={LINKS.images.miniLogo}
          h={"42px"}
        />
        <HStack spacing={4}>
          {locales.map(({ code: locale }) => (
            <MenuLanguageGroupItem
              key={locale}
              url={{ pathname: `/${locale}${route}`, query }}
              label={t(locale)}
              flag={locale}
            />
          ))}
        </HStack>
      </HStack>
      {children}
    </Box>
  );
}
