import {
  Box,
  Button,
  Center,
  Heading,
  Text,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import { useRouter } from "next/router";
import React from "react";

import NotFoundSvg from "./NotFoundSvg";
import { getPaths } from "lib/utils/paths";
import { useTranslations } from "next-intl";

const NotFoundMessage = () => {
  const t = useTranslations("components.messages");
  const theme = useTheme();
  const { push } = useRouter();

  return (
    <Center w="full" h="full" minW="full" flexGrow={1} py={12} minHeight="60vh">
      <VStack spacing={12} py={12}>
        <Box h="100px">
          <NotFoundSvg
            // eslint-disable-next-line no-inline-styles/no-inline-styles
            style={{ height: 100 }}
            color={theme.colors?.brandBlue[500]}
          />
        </Box>
        <VStack spacing={4}>
          <Heading>
            {t("notFoundMessageTitle")}
          </Heading>
          <Text color="gray.600">
            {t("notFoundMessageText")}
          </Text>
        </VStack>
        <Button colorScheme="brandBlue" onClick={() => push(getPaths.index())}>
          {t("goToHomePage")}
        </Button>
      </VStack>
    </Center>
  );
};

export default NotFoundMessage;
