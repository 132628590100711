import React from "react";
import AdminSidebarLayout from "components/layout/AdminSidebarLayout/AdminSidebarLayout";
import PublicCleanLayout from "components/layout/PublicCleanLayout";
import NotFoundMessage from "components/messages/NotFoundMessage/NotFoundMessage";
import { useUser } from "src/contexts/useUser";

const NotFoundPage = () => {
  const { isAdmin } = useUser();
  if (!isAdmin) {
    return (
      <PublicCleanLayout>
        <NotFoundMessage />
      </PublicCleanLayout>
    );
  }

  return (
    <AdminSidebarLayout>
      <NotFoundMessage />
    </AdminSidebarLayout>
  );
};

export default NotFoundPage;
